import Quill from 'quill';
import { Speaker } from 'api/speaker';
import { secondsToIso } from 'libs/duration';
import { serializeToVttFormat } from 'libs/vtt-format-parser';
import { getFirstElementByTagName } from 'libs/xml-utils';
import TrsxSection from './trsx-section';
import TrsxPhrase from './trsx-phrase';
import TrsxParagraph from './trsx-paragraph';
import TrsxChapter from './trsx-chapter';
import { CAPTION_END_SYMBOL, NBSP_DOT_SYMBOL, QUILL_CURSOR, TIME_ANCHOR_SYMBOL, TIME_STAMP_MARKER_REGEX, } from './text-utils';
// NOTE: Symbols ⏱️, ◆, zero-width-space.
// Zero-width-space is there because of bug https://trello.com/c/OMxyhQa4/2075
const NON_TEXT_CHARACTERS_REGEX = new RegExp(`[${TIME_ANCHOR_SYMBOL}${CAPTION_END_SYMBOL}${QUILL_CURSOR}]|${TIME_STAMP_MARKER_REGEX}`, 'g');
const METADATA_PHRASE_AFTER_WORD = [
    'timeAnchor',
    'captionEnd',
    'captionLineBreak',
    'captionFormat',
];
export default class DocumentExporter {
    constructor(editorController, textMetadata, speakers, creatorUserName, meta, lastPhraseTime, lastMessageId, captionMetadata) {
        this.chapters = [];
        this.activeChapter = null;
        this.activeSection = null;
        this.activeParagraph = null;
        this.lastEnd = 'PT0.00S';
        this.currentSpeaker = null;
        this.forceLabel = false;
        this.highestId = 0;
        this.trsxIds = {};
        this.lastSpeakerSign = null;
        this.lastTimeStampMarker = null;
        this.generateDocumentId = () => Math.random().toString(36).substring(2);
        this.getMediaUri = () => {
            const { host, protocol, pathname } = window.location;
            const [projectId] = pathname.split('/').slice(-1);
            const mediaUri = `${protocol}//${host}/API/Project/${projectId}/Files/MediaFile`;
            return mediaUri;
        };
        this.exportCaptionMetadata = (xmlElement) => {
            if (this.captionMetadata === null) {
                throw new Error('caption metadata is null');
            }
            Object.entries(this.captionMetadata).forEach((entry) => {
                const [name, value] = entry;
                if (name === 'maxLineLength') {
                    xmlElement.setAttribute('subtitlerMaxLineLength', String(value));
                }
                else if (name === 'defaultCaptionPosition') {
                    xmlElement.setAttribute('defaultCaptionPosition', serializeToVttFormat(value));
                }
                else if ([
                    'speedWarning',
                    'speedCriticalWarning',
                    'automaticSpeed',
                    'subtitlerMaxLineCount',
                    'defaultColor',
                    'defaultBackgroundColor',
                    'defaultBackgroundTransparency',
                    'defaultFontSize',
                    'defaultFontName',
                    'upperCaseAllText',
                    'highlightingMode',
                    'unHighlightedColor',
                ].includes(name)) {
                    xmlElement.setAttribute(name, String(value));
                }
                else if (typeof value === 'number') {
                    xmlElement.setAttribute(name, secondsToIso(value));
                }
                else if (typeof value === 'string') {
                    xmlElement.setAttribute(name, value);
                }
                else if (typeof value === 'boolean') {
                    xmlElement.setAttribute(name, value ? 'true' : 'false');
                }
                else {
                    global.logger.error('unexpected caption meta parameter', { name, value });
                }
            });
        };
        this.updateMeta = () => {
            var _a;
            if (!this.meta) {
                this.meta = document.createElementNS('http://scribe.newtonmedia.eu', 'meta');
            }
            let partialTrsxElement = getFirstElementByTagName(this.meta, 'PartialTrsx');
            if (partialTrsxElement) {
                this.meta.removeChild(partialTrsxElement);
            }
            if (this.lastMessageId !== Infinity && this.lastPhraseTime !== Infinity) {
                partialTrsxElement = document.createElementNS('http://scribe.newtonmedia.eu', 'PartialTrsx');
                partialTrsxElement.setAttribute('lastmessageid', String(this.lastMessageId));
                partialTrsxElement.setAttribute('lastphrasetime', secondsToIso(this.lastPhraseTime));
                this.meta.appendChild(partialTrsxElement);
            }
            const creatorTag = this.createChild(this.meta, 'LastEditorUserName');
            creatorTag.innerHTML = this.creatorUserName;
            const versionTag = this.createChild(this.meta, 'BeeyVersion');
            versionTag.innerHTML = VERSION;
            const editorIdTag = this.createChild(this.meta, 'EditorId');
            editorIdTag.innerHTML = this.editorController.getEditorId();
            const lastPlaybackPosition = this.createChild(this.meta, 'EditorLastPlaybackPosition');
            lastPlaybackPosition.innerHTML = secondsToIso(this.editorController.playback.time);
            if (this.captionMetadata !== null) {
                const captionMetadataTag = this.createChild(this.meta, 'CaptionMeta');
                this.exportCaptionMetadata(captionMetadataTag);
            }
            if (((_a = this.editorController.getLanguage()) === null || _a === void 0 ? void 0 : _a.isRightToLeft()) === true) {
                const isRightToLeftTag = this.createChild(this.meta, 'IsRightToLeft');
                isRightToLeftTag.innerHTML = String(true);
            }
        };
        this.editorController = editorController;
        this.textMetadata = textMetadata;
        this.speakers = speakers;
        this.creatorUserName = creatorUserName;
        this.lastPhraseTime = lastPhraseTime;
        this.lastMessageId = lastMessageId;
        this.meta = meta;
        this.captionMetadata = captionMetadata;
        this.xmlSerializer = new XMLSerializer();
        this.resetExport();
    }
    exportTXT() {
        return this.editorController.getText();
    }
    exportDump() {
        var _a;
        const quillContents = this.editorController.getContents();
        const metadata = this.textMetadata.dump();
        const { speakers, captionMetadata } = this;
        this.updateMeta();
        const trsxMeta = (_a = this.meta) === null || _a === void 0 ? void 0 : _a.innerHTML;
        const dump = {
            quillContents,
            metadata,
            speakers: speakers.documentSpeakers,
            trsxMeta,
            captionMetadata,
        };
        return JSON.stringify(dump, null, 2);
    }
    exportTRSX() {
        if (this.editorController.quill === undefined)
            throw Error('quill is undefined');
        this.resetExport();
        this.textMetadata.checkTimestampsValidity();
        const d = new Date();
        const currentTime = d.toISOString();
        this.updateMeta();
        if (this.meta === null) {
            throw new Error('missing meta element');
        }
        const documentText = this.editorController.getText();
        // @ts-ignore
        let block = this.editorController.quill.scroll.children.head;
        let tag = block.domNode.tagName;
        if (tag === 'H4') {
            // NOTE: if the first block is a speaker, create a section first to prevent
            // prevent reseting speaker on paragraph creation.
            this.newSection('', [], '');
        }
        const usedSpeakers = [];
        while (block !== null) {
            tag = block.domNode.tagName;
            const node = block.domNode;
            const blockText = this.cleanText(node.textContent);
            if (tag === 'H1' && blockText) {
                if (blockText === '') {
                    // NOTE: do not save chapters with name ''. Save as without formatting
                    this.newParagraph();
                }
                else {
                    this.newChapter(blockText);
                    this.currentSpeaker = null;
                }
            }
            else if (tag === 'H2') {
                const blot = Quill.find(node);
                const beginIndex = this.editorController.getIndex(blot);
                const sectionFormat = this.editorController.getLineFormat(beginIndex).section;
                this.newSection(blockText, sectionFormat.tags, sectionFormat.id);
                this.currentSpeaker = null;
            }
            else if (tag === 'H4') {
                const speakerid = Speaker.getId(node);
                usedSpeakers.push(speakerid);
                this.currentSpeaker = speakerid;
                this.forceLabel = true;
            }
            else if (tag === 'P') {
                const blot = Quill.find(node);
                // NOTE: getIndex is slow. This could be sped up by using deltas instead of HTML.
                const beginIndex = this.editorController.getIndex(blot);
                const format = this.editorController.getLineFormat(beginIndex);
                if (format.summary !== undefined) {
                    this.newSummary(blockText);
                    block = block.next;
                    continue;
                }
                let endIndex = 0;
                this.newParagraph();
                if (blot.next !== null) {
                    endIndex = this.editorController.getIndex(blot.next);
                }
                else {
                    endIndex = this.editorController.getLength();
                }
                const paragraphText = documentText.substring(beginIndex, endIndex);
                const timestampedWords = paragraphText.length > 1
                    ? this.textMetadata.splitTextToPhrases(paragraphText, beginIndex)
                    : []; // NOTE: Empty paragraph must not contain any phrases, not even empty.
                this.setLastValuesFromMetadata(beginIndex);
                for (let i = 0; i < timestampedWords.length; i += 1) {
                    const { text, begin, end, metadata, } = timestampedWords[i];
                    this.addMetadataPhrases('before', metadata, { begin, end });
                    this.addWordPhrase(text, { begin, end }, metadata);
                    this.addMetadataPhrases('after', metadata, { begin, end });
                }
            }
            block = block.next;
        }
        const documentId = this.generateDocumentId();
        const mediaUri = this.getMediaUri();
        const header = '<?xml version="1.0" encoding="utf-8" standalone="yes"?>\n'
            + `<transcription version="3.0" mediauri="${mediaUri}" documentid="${documentId}" created="${currentTime}">`;
        const speakersXml = this.generateSpeakers(usedSpeakers);
        const metaXml = this.serializeXml(this.meta);
        const chaptersXml = this.chapters.map((ch) => (ch.serialize())).join('');
        const xml = `${header}\n  ${metaXml}\n${chaptersXml}\n${speakersXml}</transcription>\n`;
        return xml;
    }
    setLastValuesFromMetadata(metadataIndex) {
        this.lastSpeakerSign = this.textMetadata.getMetadataAtIndex(metadataIndex - 1, 'speakerSign');
        this.lastTimeStampMarker = this.textMetadata.getMetadataAtIndex(metadataIndex - 1, 'displayTimeStamp');
    }
    addWordPhrase(text, timeStamp, metadata) {
        const metadataOnWord = this.prepareMetadataOnWord(metadata);
        const cleanedText = this.cleanText(text);
        if (cleanedText !== '') {
            this.newPhrase(cleanedText, timeStamp.begin, timeStamp.end, metadataOnWord);
        }
    }
    addMetadataPhrases(placement, metadata, timeStamp) {
        if (placement === 'before') {
            const metadataBeforeWord = this.prepareMetadataBeforeWord(metadata);
            if (Object.keys(metadataBeforeWord).length > 0) {
                this.newPhrase('', timeStamp.begin, timeStamp.begin, metadataBeforeWord);
            }
        }
        else {
            const metadataAfterWord = this.prepareMetadataAfterWord(metadata);
            if (Object.keys(metadataAfterWord).length > 0) {
                this.newPhrase('', timeStamp.end, timeStamp.end, metadataAfterWord);
            }
        }
    }
    prepareMetadataOnWord(metadata) {
        var _a;
        const metadataOnWord = {};
        if (this.lastTimeStampMarker !== null) {
            metadataOnWord.displayTimeStamp = this.lastTimeStampMarker;
        }
        this.lastTimeStampMarker = (_a = metadata.displayTimeStamp) !== null && _a !== void 0 ? _a : null;
        return metadataOnWord;
    }
    prepareMetadataBeforeWord(metadata) {
        var _a;
        const metadataBeforeWord = {};
        // NOTE: Add speaker sign from previous phrase now. This makes it use timestamps of this phrase.
        if (this.lastSpeakerSign !== null) {
            metadataBeforeWord.speakerSign = this.lastSpeakerSign;
        }
        this.lastSpeakerSign = (_a = metadata.speakerSign) !== null && _a !== void 0 ? _a : null;
        return metadataBeforeWord;
    }
    prepareMetadataAfterWord(metadata) {
        const metadataAfterWord = {};
        METADATA_PHRASE_AFTER_WORD.forEach((key) => {
            if (metadata[key] !== undefined) {
                metadataAfterWord[key] = metadata[key];
            }
        });
        return metadataAfterWord;
    }
    resetExport() {
        this.activeChapter = null;
        this.activeSection = null;
        this.activeParagraph = null;
        this.lastEnd = 'PT0.00S';
        this.currentSpeaker = null;
        this.forceLabel = false;
        this.highestId = 0;
        this.trsxIds = {};
        this.chapters = [];
    }
    newChapter(name) {
        const chapter = new TrsxChapter(name);
        this.chapters.push(chapter);
        this.activeChapter = chapter;
        this.activeSection = null;
        this.activeParagraph = null;
    }
    newSection(name, tags, id) {
        const section = new TrsxSection(name, tags, id);
        if (this.activeChapter === null) {
            this.newChapter('');
            // NOTE: eslint false alarm - activeSection was set in newSection
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (this.activeChapter === null)
                throw new Error('no active chapter');
        }
        this.activeChapter.addSection(section);
        this.activeSection = section;
        this.activeParagraph = null;
    }
    newSummary(content) {
        if (this.activeSection === null)
            throw new Error('no active section');
        this.activeSection.addSummary(content);
    }
    newParagraph() {
        const speaker = this.getTrsxId(this.currentSpeaker);
        const paragraph = new TrsxParagraph(speaker, this.lastEnd, this.lastEnd, this.forceLabel, this.captionMetadata);
        if (this.activeSection === null) {
            this.newSection('', [], '');
            // NOTE: eslint false alarm - activeSection was set in newSection
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (this.activeSection === null)
                throw new Error('no active section');
        }
        this.forceLabel = false;
        this.activeSection.addParagraph(paragraph);
        this.activeParagraph = paragraph;
    }
    cleanText(text) {
        return text.replace(NON_TEXT_CHARACTERS_REGEX, '').replace(NBSP_DOT_SYMBOL, '\u00a0');
    }
    newPhrase(text, begin, end, metadata) {
        if (this.activeParagraph === null) {
            throw new Error('active paragraph is null');
        }
        const isoBegin = secondsToIso(begin);
        const isoEnd = secondsToIso(end);
        this.lastEnd = isoEnd;
        if (this.activeParagraph.phrases.length === 0) {
            // set the begin of the trsx paragraph to the begin of the first phrase
            this.activeParagraph.begin = isoBegin;
        }
        // set the end of the paragraph to the end of the last phrase
        this.activeParagraph.end = isoEnd;
        const phrase = new TrsxPhrase(isoBegin, isoEnd, text, metadata);
        this.activeParagraph.addPhrase(phrase);
    }
    getTrsxId(stringId) {
        if (stringId === null)
            return -1;
        if (!(stringId in this.trsxIds)) {
            this.highestId += 1;
            this.trsxIds[stringId] = this.highestId;
        }
        return this.trsxIds[stringId];
    }
    generateSpeakers(usedSpeakers) {
        let sp = '  <sp>\n';
        for (let i = 0; i < this.speakers.documentSpeakers.length; i += 1) {
            const speaker = this.speakers.documentSpeakers[i];
            if (!usedSpeakers.includes(speaker.id))
                continue;
            const speakerXml = speaker.exportXml(this.getTrsxId(speaker.id));
            sp += speakerXml;
        }
        sp += '  </sp>\n';
        return sp;
    }
    createChild(parent, tagName) {
        let element = getFirstElementByTagName(parent, tagName);
        if (element === null) {
            element = document.createElementNS('http://scribe.newtonmedia.eu', tagName);
            parent.appendChild(element);
        }
        return element;
    }
    serializeXml(element) {
        const text = this.xmlSerializer.serializeToString(element);
        // horrible hack to remove xmlns (necessary for nanotrans and trsx2xml)
        return text.replace(/xmlns=""|xmlns="http:\/\/scribe.newtonmedia.eu"/g, '');
    }
}
