import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component, createRef, } from 'react';
import * as ProjectAPI from 'api/project-api';
import { createSuggestionWebSocket } from 'api/speaker-api';
import { subtitlesDefaults } from 'api/settings/user-settings';
import * as clientEnv from 'libs/client-env';
import { txt } from 'libs/i18n';
import { summarizeProject } from 'api/model/services/services-api';
import { formatTimeToISOString } from 'components/TimerangeSelectionForm';
import { ApiError } from '@newtontechnologies/beey-api-js-client/receivers';
import deepEqual from 'fast-deep-equal';
import clsx from 'clsx';
import { documentPositions } from './document-positions';
import SummaryModal from './SummaryModal';
import ProjectAlert from './ProjectAlert';
import { NONTRANSCRIPT_FORMATS } from './editor-controller';
import SpeakerSelectDialog from './SpeakerSelectDialog';
import SpeakerColorDialog from './SpeakerColorDialog';
import MessagePanel from './MessagePanel';
import DocumentToolbar from './DocumentToolbar';
import SectionTagsModal from './SectionTagsModal';
import { playbackContext } from '../WithMediaPlayback';
import 'quill/dist/quill.snow.css';
import './style.less';
import ContextMenu from '../ContextMenu';
import FindAndReplaceDialog from './FindAndReplaceDialog';
import KeywordPreview from './KeywordPreview';
import { savingStateHandler } from './saving-state-handler';
import DocumentSaver from './document-saver';
import { Recognition } from './recognition';
import DocumentHoverElement from './DocumentHoverElement';
import SectionNameModal from './SectionNameModal';
import { NBSP_DOT_SYMBOL, QUILL_CURSOR } from './text-utils';
import { valueToClasses } from './blots/section-blot';
import DocumentWidgets from './DocumentWidgets';
import { displayNotification } from './project-save-notification';
import DocumentReadonlyBar from './DocumentReadonlyBar';
export default class DocumentEditor extends Component {
    constructor(props, context) {
        super(props, context);
        this.speakerSuggestionWebSocket = null;
        this.connectedMessageStream = null;
        this.replacedDiarizationCode = null;
        this.pluginsTimerId = undefined;
        this.handleCopyAndCutEvent = (event) => {
            var _a, _b;
            const { editorController, session } = this.props;
            const selection = document.getSelection();
            if (session.login.user.settings.nonBreakingSpace === false || selection === null)
                return;
            const range = selection.getRangeAt(0);
            const fragment = range.cloneContents();
            const newElement = document.createElement('div');
            newElement.appendChild(fragment);
            const childNode = newElement.innerHTML.replaceAll(NBSP_DOT_SYMBOL, '\u00a0');
            if (newElement.childNodes.length === 1
                && newElement.childNodes[0].nodeType === Node.TEXT_NODE) {
                // NOTE: This handles cases when the user selects only a part of text with a special format
                const quillSelection = editorController.getSelection();
                if (quillSelection === null)
                    return;
                const lineFormat = editorController.getLineFormat(quillSelection.index);
                const format = Object.keys(lineFormat)[0];
                if (NONTRANSCRIPT_FORMATS.includes(format)) {
                    if (format === 'header') {
                        newElement.innerHTML = `<h1>${childNode}</h1>`;
                    }
                    if (format === 'section') {
                        const sectionClassname = valueToClasses(lineFormat.section);
                        newElement.innerHTML = `<h2 class="${sectionClassname}">${childNode}</h2>`;
                    }
                    (_a = event.clipboardData) === null || _a === void 0 ? void 0 : _a.setData('text/html', newElement.innerHTML);
                    event.preventDefault();
                    return;
                }
            }
            newElement.innerHTML = `<div>${childNode}</div>`;
            // NOTE: The child node has to be nested in a div element to preserve the unicode for
            // non-breaking space. If it is a span element, then the unicode is transformed to
            // 'nbsp&' by the browser.
            (_b = event.clipboardData) === null || _b === void 0 ? void 0 : _b.setData('text/html', newElement.innerHTML);
            event.preventDefault();
        };
        this.handleTranscriptionComplete = async () => {
            const { project, editorController } = this.props;
            await this.recognition.handleTranscriptionComplete();
            if (project.currentTrsxId !== null) {
                // NOTE: After transcription is finished, trigger save to ensure that
                // we don't leave incomplete trsx in the project, but only if it was edited.
                editorController.triggerSave();
            }
        };
        this.handleHistoryLoaded = () => {
            const { editorController, project } = this.props;
            void editorController.keywords.highlightAllKeywords(project.keywordsHighlight);
        };
        this.showSpeakerReplaceModal = (speakerNode, replacingEverywhere) => {
            const { editorController } = this.props;
            const speaker = editorController.speakers.getSpeakerByElement(speakerNode);
            this.replacedDiarizationCode = speaker ? speaker.diarizationCode : null;
            if (speaker) {
                this.setState({
                    speakerSelectDialog: { visible: true, node: speakerNode },
                    replacingEverywhere,
                    replacedSpeaker: speaker,
                });
            }
        };
        this.showAssignSpeakerModal = () => {
            this.setState({
                speakerSelectDialog: { visible: true, node: null },
                replacingEverywhere: false,
                replacedSpeaker: null,
            });
        };
        this.toggleKeywordPreview = () => {
            const { isKeywordPreviewVisible } = this.state;
            this.setState({ isKeywordPreviewVisible: !isKeywordPreviewVisible });
        };
        this.showSectionNameModal = (target) => {
            this.setState({
                sectionName: {
                    modalVisible: true,
                    target: target !== null && target !== void 0 ? target : null,
                },
            });
        };
        this.showSummaryModal = (summaryIndex) => {
            this.setState({
                summary: {
                    modalVisible: true,
                    from: summaryIndex.from,
                    to: summaryIndex.to,
                    state: 'ready',
                },
            });
        };
        this.closeSummaryModal = () => {
            const { summary } = this.state;
            this.setState({ summary: Object.assign(Object.assign({}, summary), { modalVisible: false }) });
        };
        this.closeSectionNameModal = () => {
            const { sectionName } = this.state;
            this.setState({ sectionName: Object.assign(Object.assign({}, sectionName), { modalVisible: false }) });
        };
        // TODO: Refactoring: it would be nice to move most of the code in generateSummary to
        // SummaryModal to avoid clutter in DocumentEditor.
        this.generateSummary = async (formValues) => {
            const { summary } = this.state;
            const { session, project, editorController } = this.props;
            const beginInIsoFormat = formatTimeToISOString(editorController.textMetadata.getBeginAtIndex(summary.from));
            const endTime = editorController.textMetadata.getEndAtIndex(summary.to);
            const endInIsoFormat = formatTimeToISOString(endTime === Infinity ? 1000000 : endTime);
            this.setState({ summary: Object.assign(Object.assign({}, summary), { state: 'loading' }) });
            try {
                const response = await summarizeProject(session.connection, project.id, formValues.summaryLocale, beginInIsoFormat, endInIsoFormat);
                editorController.insertTextWithFormat(summary.from, response.summarization.join('\n').replaceAll('\n\n', '\n'), { summary: 'button' });
                editorController.summary.deletePreviousSummary(summary.from);
                this.setState({ summary: Object.assign(Object.assign({}, summary), { modalVisible: false, state: 'ready' }) });
            }
            catch (e) {
                let errorMessage;
                if (e instanceof ApiError) {
                    errorMessage = e.toString();
                }
                else if (e instanceof Error) {
                    errorMessage = e.message;
                }
                else {
                    errorMessage = 'unexpected error';
                }
                this.setState({
                    summary: Object.assign(Object.assign({}, summary), { modalVisible: true, state: 'failed', errorMessage }),
                });
                global.logger.error('summarization failed', {}, e);
            }
        };
        this.showEditSectionTagsModal = (node) => {
            const { notification } = this.props;
            const getTagsFromNode = (element) => {
                // tag value must be saved as a class. If it were saved another
                // way, quill may not preserve it if the node is updated. Quill always preserves classes.
                const tags = [];
                const prefix = 'tagname-';
                for (let i = 0; i < element.classList.length; i += 1) {
                    const className = element.classList[i];
                    if (className.startsWith(prefix)) {
                        const decodedTag = decodeURIComponent(className.substring(prefix.length));
                        tags.push(decodedTag);
                    }
                }
                return tags;
            };
            if (node.textContent === '' || node.textContent === QUILL_CURSOR) {
                // NOTE: Adding tags to empty sections causes various problems and these tags cannot be
                // saved to trsx. We minimise these problems by not allowing adding tag to empty heading.
                notification.error({
                    message: txt('noTagForEmptyHeading'),
                    placement: 'topRight',
                    duration: 10,
                });
                return;
            }
            this.setState({
                sectionTagsModal: {
                    visible: true,
                    tags: getTagsFromNode(node),
                    node,
                },
            });
        };
        this.handleSpeakerSelected = (speaker, replacingEverywhere) => {
            const { editorController } = this.props;
            const { replacedSpeaker } = this.state;
            editorController.handleSpeakerSelected(speaker, replacingEverywhere, replacedSpeaker);
            this.setState({ replacingEverywhere });
            this.closeSpeakerSelectDialog();
        };
        this.closeSectionTagsModal = () => {
            this.setState({
                sectionTagsModal: {
                    visible: false,
                    tags: [],
                    node: null,
                },
            });
        };
        this.triggerFindAndReplace = (action) => {
            const { editorController } = this.props;
            const { findAndReplaceState, speakerSelectDialog } = this.state;
            if (action === 'close' && findAndReplaceState.isVisible) {
                this.setState({ findAndReplaceState: { isVisible: false, mode: 'find' } });
                editorController.getFindAndReplace().reset();
            }
            if (speakerSelectDialog.visible) {
                // if speaker modal is visible, find and replace should not be triggered.
                return;
            }
            if (action === 'open-find') {
                this.setState({ findAndReplaceState: { isVisible: true, mode: 'find' } });
            }
            if (action === 'open-find-replace') {
                this.setState({ findAndReplaceState: { isVisible: true, mode: 'findAndReplace' } });
            }
        };
        this.handleSectionTagsSelected = (tags, targetNode) => {
            const { editorController } = this.props;
            editorController.handleSectionTagsSelected(tags, targetNode);
            this.closeSectionTagsModal();
        };
        this.closeSpeakerSelectDialog = () => {
            const { speakerSelectDialog } = this.state;
            const { editorController } = this.props;
            if (!speakerSelectDialog.visible)
                return;
            this.setState({
                speakerSelectDialog: { visible: false, node: null },
                replacedSpeaker: null,
                replacingEverywhere: false,
            });
            editorController.handleCloseSpeakerSelector();
        };
        this.handleSpeakerDeleted = (e, speaker) => {
            e.stopPropagation();
            const { editorController } = this.props;
            editorController.speakers.deleteSpeakerFromDatabase(speaker, false);
        };
        this.handleWidgetsClicked = (node, action) => {
            if (action === 'editSections') {
                this.showEditSectionTagsModal(node);
            }
        };
        this.checkPlugins = () => {
            const { notification } = this.props;
            const grammarlyPopups = document.querySelector('grammarly-popups');
            if (grammarlyPopups !== null) {
                notification.warning({
                    message: txt('activeGrammarlyTitle'),
                    description: txt('activePluginDescription'),
                    duration: 0,
                });
            }
            const deeplController = document.querySelector('deepl-input-controller');
            if (deeplController !== null) {
                notification.warning({
                    message: txt('activeDeeplTitle'),
                    description: txt('activePluginDescription'),
                    duration: 0,
                });
            }
        };
        this.checkRecentEdits = () => {
            const { editorController, notification } = this.props;
            const warning = editorController.getRecentEditWarning();
            if (warning === null)
                return;
            notification.warning({
                message: txt('someoneIsEditingHead'),
                description: `${txt('someoneIsEditingDescription')} ${warning}`,
                placement: 'topRight',
                duration: 0,
            });
        };
        this.shouldDisplayEditor = () => {
            const { documentLoadingState } = this.state;
            const { mediaProcessingStatus, projectErrorStatus, } = this.props;
            if (documentLoadingState === 'loaded') {
                // If there was a document (even partial) successfully imported, show it,
                // regardless of errors. This is useful for users who edited document before
                // transcription crashed.
                return true;
            }
            if (projectErrorStatus !== 'no-error') {
                return false;
            }
            return (mediaProcessingStatus === 'words-arriving'
                || mediaProcessingStatus === 'completed');
        };
        this.state = {
            speakerSelectDialog: {
                visible: false,
                node: null,
            },
            replacingEverywhere: false,
            replacedSpeaker: null,
            sectionTagsModal: {
                visible: false,
                tags: [],
                node: null,
            },
            findAndReplaceState: {
                isVisible: false,
                mode: 'find',
            },
            isKeywordPreviewVisible: false,
            documentLoadingState: 'loading',
            summary: {
                modalVisible: false,
                from: 0,
                to: 0,
                state: 'ready',
            },
            sectionName: {
                modalVisible: false,
                target: null,
            },
            speakerColorDialog: {
                visible: false,
            },
            isDocumentComplete: false,
            isEditorVisible: false,
        };
        const { editorController, project, onProjectUpdated, mediaProcessingStatus, onLeaveEditorRequested, session, messageStream, enqueueProjectUpdate, } = this.props;
        this.documentSaver = new DocumentSaver(project, onProjectUpdated, enqueueProjectUpdate, session, editorController, mediaProcessingStatus, onLeaveEditorRequested, displayNotification);
        this.recognition = new Recognition(editorController, messageStream, this.handleHistoryLoaded);
        editorController.onSelectSpeaker = this.showAssignSpeakerModal;
        editorController.onReplaceSpeaker = this.showSpeakerReplaceModal;
        editorController.onTriggerFindAndReplace = this.triggerFindAndReplace;
        editorController.onGenerateSummary = this.showSummaryModal;
        editorController.onSectionHeadingClicked = this.showSectionNameModal;
        editorController.onDocumentChanged = (shouldRequestSave) => {
            if (shouldRequestSave) {
                void this.documentSaver.handleActivity();
            }
        };
        this.editorElementRef = createRef();
    }
    async componentDidUpdate(prevProps, prevState) {
        const { isPlayerReady, tutorialContinue, project, onProjectUpdated, editorController, mediaProcessingStatus, onLeaveEditorRequested, session, } = this.props;
        const { documentLoadingState, isEditorVisible, } = this.state;
        if (prevProps.mediaProcessingStatus === 'words-arriving'
            && mediaProcessingStatus === 'completed') {
            await this.handleTranscriptionComplete();
        }
        const isPlayerAndDocumentReady = documentLoadingState !== 'loading' && isPlayerReady;
        const wasPlayerAndDocumentReady = prevState.documentLoadingState !== 'loading' && prevProps.isPlayerReady;
        if (isPlayerAndDocumentReady && !wasPlayerAndDocumentReady) {
            tutorialContinue();
            void this.handlePlayerAndDocumentLoadingFinished();
        }
        const newIsEditorVisible = this.shouldDisplayEditor();
        if (prevState.isEditorVisible !== newIsEditorVisible) {
            // warning disabled because infinite loop is prevented by the condition
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ isEditorVisible: newIsEditorVisible });
        }
        if (isEditorVisible !== prevState.isEditorVisible) {
            // Grammarly popups is rendered after a while
            this.pluginsTimerId = window.setTimeout(this.checkPlugins, 5000);
        }
        if (!deepEqual(prevProps.project.keywordsHighlight, project.keywordsHighlight)) {
            void editorController.keywords.highlightAllKeywords(project.keywordsHighlight);
        }
        if (prevProps.project.accessToken !== project.accessToken) {
            this.documentSaver.handleProjectUpdate(project);
        }
        // update documentSaver just in case that some props that were passed
        // in the constructor changed.
        this.documentSaver.update(project, onProjectUpdated, session, editorController, mediaProcessingStatus, onLeaveEditorRequested);
    }
    async componentDidMount() {
        const { project, editorController, messageStream, projectErrorStatus, onTranscriptionLoadFailed, session, editorPriority, notification, } = this.props;
        if (this.editorElementRef.current === null) {
            throw new Error('editorElementRef is not set');
        }
        editorController.mount(this.editorElementRef.current, editorPriority);
        if (projectErrorStatus !== 'no-error') {
            this.setState({ documentLoadingState: 'failed' });
            return;
        }
        this.connectedMessageStream = await messageStream.connect();
        this.editorElementRef.current.addEventListener('copy', this.handleCopyAndCutEvent);
        this.editorElementRef.current.addEventListener('cut', this.handleCopyAndCutEvent);
        this.speakerSuggestionWebSocket = await createSuggestionWebSocket(session.connection);
        try {
            let wasDocumentImported = false;
            if (project.currentTrsxId !== null) {
                // A user trsx is available - possibly edited partial transcription.
                const currentTrsx = await ProjectAPI.fetchTrsx(session.connection, project, 'currentTrsx');
                editorController.importTrsx(currentTrsx, false, project);
                this.checkRecentEdits();
                wasDocumentImported = true;
                if (project.originalTrsxId === null) {
                    // we are loading a partial trsx - initialize aligner with it.
                    // new words will be added as the transcription continues, but
                    // the words that are in the partial trsx will be skipped.
                    editorController.initializeAligner(currentTrsx);
                }
            }
            if (project.originalTrsxId !== null) {
                const originalTrsx = await ProjectAPI.fetchTrsx(session.connection, project, 'originalTrsx');
                // Fills the editor from original trsx, preserving the partial trsx.
                if (project.currentTrsxId !== null) {
                    editorController.fillPartialTrsx(originalTrsx);
                }
                else {
                    editorController.importTrsx(originalTrsx, false, project);
                    wasDocumentImported = true;
                }
                editorController.initializeAligner(originalTrsx);
            }
            if (wasDocumentImported) {
                this.setState({ documentLoadingState: 'loaded' });
            }
            else {
                this.setState({ documentLoadingState: 'no-document' });
            }
        }
        catch (error) {
            this.setState({ documentLoadingState: 'failed' });
            global.logger.error('Opening or initializing project failed', {}, error);
            notification.error({
                message: `Opening or initializing project failed: ${String(error)}`,
                placement: 'topRight',
                duration: 0,
            });
            onTranscriptionLoadFailed();
            return;
        }
        this.setState({
            isDocumentComplete: project.originalTrsxId !== null,
        });
    }
    componentWillUnmount() {
        var _a, _b, _c;
        this.recognition.destroy();
        if (this.connectedMessageStream !== null) {
            void this.connectedMessageStream.close();
        }
        (_a = this.speakerSuggestionWebSocket) === null || _a === void 0 ? void 0 : _a.close();
        window.clearTimeout(this.pluginsTimerId);
        this.documentSaver.cleanup();
        savingStateHandler.updateStatus('saved');
        (_b = this.editorElementRef.current) === null || _b === void 0 ? void 0 : _b.removeEventListener('copy', this.handleCopyAndCutEvent);
        (_c = this.editorElementRef.current) === null || _c === void 0 ? void 0 : _c.removeEventListener('cut', this.handleCopyAndCutEvent);
    }
    render() {
        var _a, _b, _c, _d;
        const { speakerSelectDialog, replacingEverywhere, sectionTagsModal, documentLoadingState, isEditorVisible, findAndReplaceState, summary, replacedSpeaker, speakerColorDialog, isKeywordPreviewVisible, sectionName, } = this.state;
        const { position, className, editorController, mediaProcessingStatus, projectErrorStatus, projectQueuePosition, isCaptionMode, tutorialGoNext, tutorialCurrentStep, retrieveTutorialState, project, session, onEditorWidthSelected, enqueueProjectUpdate, onScroll, editorPriority, isToolbarHidden, onToggleToolbar, } = this.props;
        const { mediaSource, playerState } = this.context;
        const defaultSpeakerColor = (_b = (_a = editorController.captions.parameters) === null || _a === void 0 ? void 0 : _a.defaultColor) !== null && _b !== void 0 ? _b : subtitlesDefaults.speakerColor;
        const forceToolbarHidden = isToolbarHidden && retrieveTutorialState() !== 'running';
        const isRightToLeft = (_d = (_c = editorController.getLanguage()) === null || _c === void 0 ? void 0 : _c.isRightToLeft()) !== null && _d !== void 0 ? _d : false;
        const onPressEnter = (e) => {
            if (e.key === 'Enter' && tutorialCurrentStep === 1) {
                tutorialGoNext();
            }
        };
        const handleScroll = () => {
            onScroll(editorController.getPlaybackVerticalPosition());
        };
        const showNativeContextMenu = (event) => {
            if (!event.altKey) {
                event.stopPropagation();
            }
        };
        const handleSpeakerRemoved = () => {
            const { speakerSelectDialog: { node } } = this.state;
            if (node !== null) {
                editorController.handleRemoveSpeaker(node);
            }
        };
        const openSpeakerColorDialog = () => {
            this.setState({ speakerColorDialog: Object.assign(Object.assign({}, speakerColorDialog), { visible: true }) });
        };
        const closeSpeakerColorDialog = () => {
            this.setState({ speakerColorDialog: Object.assign(Object.assign({}, speakerColorDialog), { visible: false }) });
        };
        const isVideoUnplayable = () => {
            if (playerState.status !== 'ready') {
                return false;
            }
            if (mediaSource.type === 'local') {
                // for local files we cannot reliably tell if there should be a video or not.
                // Browser guesses it from the file extension, but mp4 could be audio only.
                return false;
            }
            return mediaSource.remoteHasVideo && playerState.video === 'no-video';
        };
        const positions = documentPositions(position, isToolbarHidden);
        const remUnit = parseFloat(getComputedStyle(document.documentElement).fontSize);
        return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        _jsx("div", { style: position, className: className, onKeyUp: onPressEnter, children: _jsx(ContextMenu, { editorController: editorController, isCaptionMode: isCaptionMode, disabled: !isEditorVisible || project.isReadOnly, project: project, enqueueProjectUpdate: enqueueProjectUpdate, children: _jsxs("div", { className: "text-editor", onContextMenu: showNativeContextMenu, style: position, children: [isVideoUnplayable()
                            ? (_jsx(ProjectAlert, { type: "warning", title: txt('videoNotAvailable'), description: _jsx("p", { children: txt('convertToH264') }), contactSupport: true, closable: true })) : null, _jsx(MessagePanel, { mediaProcessingStatus: mediaProcessingStatus, projectErrorStatus: projectErrorStatus, projectQueuePosition: projectQueuePosition, documentLoadingFinished: documentLoadingState !== 'loading', project: project }), isEditorVisible
                            ? (editorPriority === 'main'
                                ? (_jsx(DocumentToolbar, { position: positions.toolbar, onToggleToolbar: onToggleToolbar, isToolbarHidden: forceToolbarHidden, triggerFindAndReplace: this.triggerFindAndReplace, editorController: editorController, isCaptionMode: isCaptionMode, readOnly: project.isReadOnly, onOpenSpeakerColorDialog: openSpeakerColorDialog, toggleKeywordPreview: this.toggleKeywordPreview, onEditorWidthSelected: onEditorWidthSelected }))
                                : (_jsx(DocumentReadonlyBar, { position: positions.toolbar, isHidden: forceToolbarHidden, text: txt('readOnlyBar') }))) : null, _jsx(DocumentHoverElement, { disabled: project.isReadOnly, editorController: editorController, children: _jsx("div", { ref: this.editorElementRef, className: clsx('ql-container beey-tutorial-step-speaker sub-mode-tutorial-step-delete beey-tutorial-step-edit', { 'read-only': project.isReadOnly }), style: Object.assign(Object.assign({ display: isEditorVisible ? 'block' : 'none' }, positions.document), { fontSize: `${session.login.user.settings.editorFontDelta + remUnit}px` }), role: "textbox", tabIndex: 0, "aria-label": "Text Editor", onScroll: handleScroll, children: editorController.speakers !== undefined && editorController.sections !== undefined
                                    ? (_jsx(DocumentWidgets, { editorRef: this.editorElementRef, editorController: editorController, isCaptionMode: isCaptionMode, isRightToLeft: isRightToLeft, onClick: this.handleWidgetsClicked, disabled: project.isReadOnly })) : null }) }), _jsx(SummaryModal, { onClose: this.closeSummaryModal, visible: summary.modalVisible, onSubmit: this.generateSummary, status: summary.state, errorMessage: summary.errorMessage }), _jsx(SectionNameModal, { visible: sectionName.modalVisible, target: sectionName.target, onSectionRemoved: (target) => editorController.sections.removeSection(target), onSubmit: (sectionValues, target) => {
                                editorController.sections.handleSectionNameSelected(sectionValues, target);
                                this.closeSectionNameModal();
                            }, onClose: this.closeSectionNameModal, project: project }), _jsx("div", { children: speakerSelectDialog.visible && (_jsx(SpeakerSelectDialog, { onSpeakerSelected: this.handleSpeakerSelected, documentSpeakers: editorController.speakers.documentSpeakers, speakerSuggestionWebSocket: this.speakerSuggestionWebSocket, onClose: this.closeSpeakerSelectDialog, diarizationCode: this.replacedDiarizationCode, transcriptionLanguage: editorController.getLanguage(), isCaptionMode: isCaptionMode, defaultSpeakerColor: defaultSpeakerColor, replacedSpeaker: replacedSpeaker, replaceEverywhere: replacingEverywhere, onSpeakerDeleted: this.handleSpeakerDeleted, onSpeakerRemoved: handleSpeakerRemoved })) }), _jsxs("div", { children: [sectionTagsModal.visible ? (_jsx(SectionTagsModal, { onCancel: this.closeSectionTagsModal, onSelectTags: this.handleSectionTagsSelected, targetNode: sectionTagsModal.node })) : null, findAndReplaceState.isVisible && (_jsx(FindAndReplaceDialog, { findAndReplace: editorController.getFindAndReplace(), mode: findAndReplaceState.mode, providedSearchedValue: editorController.getSelectedText(), onClose: () => this.triggerFindAndReplace('close'), project: project })), session.login.hasClaim('keywords:enabled')
                                    && clientEnv.getShowKeywords() === true
                                    && isKeywordPreviewVisible
                                    ? (_jsx(KeywordPreview, { keywordsHighlight: project.keywordsHighlight, keywords: editorController.keywords, onClose: this.toggleKeywordPreview })) : null] }), speakerColorDialog.visible ? (_jsx(SpeakerColorDialog, { onClose: closeSpeakerColorDialog, editorController: editorController, defaultSpeakerColor: defaultSpeakerColor })) : null] }) }) }));
    }
    handlePlayerAndDocumentLoadingFinished() {
        const { editorController, project } = this.props;
        const { isDocumentComplete } = this.state;
        const urlParams = new URLSearchParams(window.location.search);
        const timestampInMs = urlParams.get('timestamp');
        const search = urlParams.get('search');
        const speakerName = urlParams.get('speakername');
        if (timestampInMs !== null && search !== null) {
            editorController.highlightSearchResult(timestampInMs, search);
        }
        else if (speakerName !== null) {
            editorController.highlightSpeaker(speakerName);
        }
        else {
            // focuses the editor where the user left it.
            editorController.syncTextHighlightWithPlayback(true);
        }
        editorController.handlePlayerAndDocumentLoadingFinished(isDocumentComplete);
        if (project.isReadOnly === true) {
            editorController.disableEditing();
        }
    }
}
DocumentEditor.contextType = playbackContext;
