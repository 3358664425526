import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import currentWord from 'resources/img/currentWord.png';
import oneWordChunks from 'resources/img/oneWordChunks.png';
import halfSecondChunks from 'resources/img/halfSecondChunks.png';
import upToCurrentWord from 'resources/img/upToCurrentWord.png';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
const imageMap = {
    CurrentWord: currentWord,
    OneWordChunks: oneWordChunks,
    HalfSecondChunks: halfSecondChunks,
    UpToCurrentWord: upToCurrentWord,
};
const SubtitlesPreview = ({ highlightingMode }) => {
    return (_jsxs(_Fragment, { children: [_jsxs("span", { children: [txt('preview'), ":"] }), _jsx("img", { alt: "subtitles preview", src: imageMap[highlightingMode], className: styles.highlightingImage })] }));
};
export default SubtitlesPreview;
