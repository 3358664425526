import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider, Form, InputNumber, Switch, } from 'antd';
import { txt } from 'libs/i18n';
import { useSession } from 'components/Authenticated';
import TimerangeSelectionForm, { formatTimeToISOString, getTimeInSeconds } from 'components/TimerangeSelectionForm';
import TranscodeJobsInfo from 'components/TranscodeJobsInfo';
import SubtitlesPreview from 'components/SubtitlesPreview';
import { SUBTITLE_LENGTH } from '../../CaptionReviewModal';
import styles from './style.module.less';
const BurnSubtitles = ({ visible, project, isCaptionMode, selectedTextTimestamps, isLoading, form, highlightingMode, }) => {
    const { session } = useSession();
    const { login: { user: { settings: { subtitlesExport: { maxLineLength }, }, }, }, } = session;
    const durationInSeconds = getTimeInSeconds(project.length);
    const selectedRangeEnd = selectedTextTimestamps === null
        || selectedTextTimestamps.end === Infinity ? durationInSeconds : selectedTextTimestamps.end;
    const initialValues = {
        maxLineLength,
        begin: selectedTextTimestamps === null
            ? '00:00:00.000'
            : formatTimeToISOString(selectedTextTimestamps.begin),
        end: selectedTextTimestamps === null
            // some projects return duration without milliseconds
            ? formatTimeToISOString(durationInSeconds)
            : formatTimeToISOString(selectedRangeEnd),
    };
    const handleFullLengthSelected = () => {
        form.setFieldsValue({
            begin: '00:00:00.000',
            end: formatTimeToISOString(durationInSeconds),
        });
    };
    const selectedBegin = Form.useWatch('begin', form);
    const selectedEnd = Form.useWatch('end', form);
    const isUncutMedia = selectedBegin === '00:00:00.000' && selectedEnd === formatTimeToISOString(durationInSeconds);
    return (_jsxs("div", { children: [highlightingMode !== 'None'
                ? (_jsx(SubtitlesPreview, { highlightingMode: highlightingMode }))
                : null, isCaptionMode
                ? null
                : (_jsxs(_Fragment, { children: [highlightingMode === 'OneWordChunks'
                            || highlightingMode === 'HalfSecondChunks' ? null : (_jsxs(_Fragment, { children: [_jsx("h4", { className: styles.heading, children: txt('subtitleLineLength') }), _jsxs(Form.Item, { required: true, children: [_jsx(Form.Item, { name: "maxLineLength", noStyle: true, rules: [
                                                {
                                                    required: true,
                                                    type: 'number',
                                                    min: SUBTITLE_LENGTH.min,
                                                    max: SUBTITLE_LENGTH.max,
                                                    message: `${txt('range')} ${SUBTITLE_LENGTH.min} - ${SUBTITLE_LENGTH.max}`,
                                                },
                                            ], children: _jsx(InputNumber, { type: "number", autoFocus: true }) }), ' ', txt('charactersPerLine')] })] })), _jsx(Form.Item, { name: "upperCaseAllText", label: txt('setAllCaps'), valuePropName: "checked", children: _jsx(Switch, { size: "small" }) })] })), _jsx("h4", { className: styles.heading, children: txt('selectedTimeRange') }), _jsx("p", { children: txt('canSelectText') }), _jsx(TimerangeSelectionForm, { onFullLengthSelected: handleFullLengthSelected, alertText: txt('burnTimeInfo'), wholeLength: isUncutMedia, buttonText: txt('burnSubs'), durationInSeconds: durationInSeconds }), _jsx(Divider, { className: styles.divider }), _jsx("h4", { className: styles.heading, children: txt('burntVideos') }), _jsx(TranscodeJobsInfo, { workerType: "burn-subs", project: project, visible: visible, form: form, initialValues: initialValues, isLoading: isLoading })] }));
};
export default BurnSubtitles;
